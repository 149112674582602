export const HTMLComponent = async (assetName: any, data: string) => {
  let finalResult: string = "";
  let formattedText;

  // Check if data is not a string, then handle it as a Blob
  if (typeof data !== "string") {
    const blob = new Blob([data], { type: "application/octet-stream" });

    try {
      // Wait for the blob text to resolve
      finalResult = await blob.text();
    } catch (error) {
      console.error("Error reading the Blob:", error);
    }
  }

  // Determine whether data is string or processed blob text
  if (typeof data === "string") {
    formattedText = data?.replace(/\n/g, "<br>");
  } else {
    formattedText = finalResult?.replace(/\n/g, "<br>");
  }

  // Open a new window and display the formatted content
  const newWindow: any = window.open("", "", "width=800,height=600");
  newWindow.document.write(`
    <html>
      <head>
        <title>${assetName}</title>
      </head>
      <body>
        <pre style="white-space: pre-wrap; font-family: monospace;">${formattedText}</pre>
      </body>
    </html>
  `);
  newWindow.document.close();
};
