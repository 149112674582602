import axios from "axios";
import {
  GET_KNOWLEDGE_ASSETS,
  INTERACT_CHAT_ENDPOINT,
  INTERACT_CHAT_KNOWLEDGE_ENDPOINT,
  PREVIEW_COBOL,
  PREVIEW_SAS,
  UPLOAD_ENDPOINT,
  DELETE_ASSETS,
  PROJECT_NAME,
  DOWNLOAD_ASSET,
  EDIT_ASSET_NAME,
  UPDATE_PROJECT_DEFINITION,
  GET_FILE_TYPES,
  PROJECT_DEFINATIONS,
} from "../constants/endpoints";
import { SUCCESS_MESSAGE } from "../pages/Constants";

export const uploadFile = async (
  file: File,
  operation: string,
  assetName: string,
  type: string,
  projectName: string
): Promise<{ success: boolean; data?: any; message?: string }> => {
  try {
    const formData = new FormData();

    if (!assetName || assetName.trim() === "") {
      throw new Error("Asset name is required.");
    }

    formData.append("file", file);
    formData.append("operation", operation);
    formData.append("asset_name", assetName);
    formData.append("asset_type", type);
    formData.append("project_name", projectName);

    const response = await axios.post(
      `${process.env.REACT_APP_API_GATEWAY_URL}${UPLOAD_ENDPOINT}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${sessionStorage.getItem("react-token")}`,
        },
      }
    );

    return {
      success: true,
      data: response?.data?.data,
      message: response?.data?.message,
    };
  } catch (error: any) {
    return {
      success: false,
      message:
        error?.response?.data?.message || error?.message || "An error occurred",
    };
  }
};

export const interactVectorDb = async (
  userInput: string
): Promise<{ success: boolean; data?: any; message?: string }> => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_GATEWAY_URL}${INTERACT_CHAT_ENDPOINT}`,
      {
        user_input: userInput,
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("react-token")}`,
        },
      }
    );

    const { data } = response;
    if (data?.status === SUCCESS_MESSAGE) {
      return {
        success: true,
        data: data?.response,
        message: data?.message,
      };
    }

    return { success: false, message: data?.message };
  } catch (error: any) {
    return { success: false, message: error?.message || "An error occurred" };
  }
};

export const interactKnowledgeAsset = async (body: {
  user_input: string;
  selected_asset: string;
}): Promise<{ success: boolean; data?: any; message?: string }> => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_GATEWAY_URL}${INTERACT_CHAT_KNOWLEDGE_ENDPOINT}`,
      body,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("react-token")}`,
        },
      }
    );

    const { data } = response;

    // return { success: true, data: "data.data" };
    if (response.data?.status === "success") {
      return {
        success: true,
        data: response?.data,
        message: data.message,
      };
    }

    return { success: false, message: data.message };
  } catch (error: any) {
    return { success: false, message: error?.message || "An error occurred" };
  }
};
export const previewSas = async (fileName: string) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_GATEWAY_URL}${PREVIEW_SAS}${fileName}`,
      {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("react-token")}`,
        },
      }
    );

    const { data, status } = response;
    if (status === 200) {
      return {
        success: true,
        data: data,
      };
    } else {
      return {
        success: true,
        data: data?.message,
      };
    }
  } catch (error) {
    console.error("Error opening file: ", error);
  }
};

export const previewCobol = async (fileName: string) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_GATEWAY_URL}${PREVIEW_COBOL}${fileName}`,
      {
        // responseType: "blob", // Important to handle binary data
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("react-token")}`,
        },
      }
    );
    const { data, status } = response;
    if (status === 200) {
      return {
        success: true,
        data: data,
      };
    } else {
      return {
        success: true,
        data: data?.message,
      };
    }
  } catch (error) {
    console.error("Error opening file: ", error);
  }
};

export const getKnowledgeAssetsApi = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_GATEWAY_URL}${GET_KNOWLEDGE_ASSETS}`,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("react-token")}`,
        },
      }
    );
    const { data } = response;
    if (data.status === "success") {
      return {
        success: true,
        data: data,
        message: data?.message ?? "",
      };
    }

    return { success: false, message: data.message };
  } catch (error: any) {
    return { success: false, message: error?.message || "An error occurred" };
  }
};

export const deleteKnowledgeAssets = async (asset_id: string) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_GATEWAY_URL}${DELETE_ASSETS}`,
      {
        asset_id,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("react-token")}`,
        },
      }
    );

    return {
      success: true,
      data: response?.data?.data,
      message: response?.data?.message,
    };
  } catch (error: any) {
    return {
      success: false,
      message:
        error?.response?.data?.message || error?.message || "An error occurred",
    };
  }
};

export const getProjectName = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_GATEWAY_URL}${PROJECT_NAME}`,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("react-token")}`,
        },
      }
    );
    const { data } = response;
    if (data.status === "success") {
      return {
        success: true,
        data: data,
        message: data?.message ?? "",
      };
    }

    return { success: false, message: data?.message };
  } catch (error: any) {
    return { success: false, message: error?.message || "An error occurred" };
  }
};

export const downloadAsset = async (asset_name: string) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_GATEWAY_URL}${DOWNLOAD_ASSET}${asset_name}`,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("react-token")}`,
        },
        responseType: "blob",
      }
    );
    return { success: true, data: response?.data };
  } catch (error) {
    if (error instanceof Error) {
      console.error("Error downloading asset:", error?.message);
      return { success: false, message: error?.message };
    } else {
      console.error("Unknown error downloading asset:", error);
      return { success: false, message: "An unknown error occurred" };
    }
  }
};

export const editAssetName = async (
  project_name: string,
  new_asset_name: string,
  old_asset_name: string
) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_GATEWAY_URL}${EDIT_ASSET_NAME}?project_name=${project_name}&new_asset_name=${new_asset_name}&old_asset_name=${old_asset_name}`,
      {
        project_name,
        new_asset_name,
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("react-token")}`,
        },
      }
    );

    const { data } = response;
    if (data?.status === SUCCESS_MESSAGE) {
      return {
        success: true,
        message: data?.message ?? "Asset name updated successfully",
      };
    }
    return {
      success: false,
      message: data?.message ?? "Failed to update asset name",
    };
  } catch (error: any) {
    console.error("Error editing asset name:", error);
    return { success: false, message: error?.message || "An error occurred" };
  }
};
export const updateProjectDefinition = async (
  project_name: string,
  metadata: object,
  file_types: string[]
) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_GATEWAY_URL}${UPDATE_PROJECT_DEFINITION}`,
      {
        project_name,
        metadata,
        file_types,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("react-token")}`,
        },
      }
    );

    return {
      success: true,
      data: response?.data?.data,
      message: response?.data?.message,
    };
  } catch (error: any) {
    return {
      success: false,
      message:
        error?.response?.data?.message || error?.message || "An error occurred",
    };
  }
};
export const getFileTypes = async (
  project_name: string
): Promise<{ success: boolean; data?: any; message: string }> => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_GATEWAY_URL}${GET_FILE_TYPES}?project_name=${project_name}`,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("react-token")}`,
        },
      }
    );
    const { data } = response;

    if (data) {
      return {
        success: true,
        data: data.file_types,
        message: data?.message ?? "",
      };
    }

    return {
      success: false,
      message: data?.message || "Failed to fetch file types",
    };
  } catch (error: any) {
    return {
      success: false,
      message:
        error?.response?.data?.message || error.message || "An error occurred",
    };
  }
};

export const projectDefinationApi = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_GATEWAY_URL}${PROJECT_DEFINATIONS}`,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("react-token")}`,
        },
      }
    );

    const { data, status } = response;
    if (status === 200) {
      return {
        success: true,
        data: data,
      };
    } else {
      return {
        success: true,
        data: data?.message,
      };
    }
  } catch (error) {
    console.error("Error opening file: ", error);
  }
};
