import React, { useState, useEffect } from "react";
import {
  Box,
  Autocomplete,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import {
  editAssetName,
  uploadFile,
  getFileTypes,
  getProjectName,
} from "../services/BackendServices";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../redux/Hook";
import {
  ASSET_NAME_UPDATE_ERROR,
  ASSET_NAME_UPDATE_SUCCESS,
  COBOL,
  SAS,
} from "./Constants";
import "./Styles.css";

const CreateKnowledge: React.FC = () => {
  const notification = useAppSelector((state) => state.NotificationAlert);
  const dispatch = useDispatch();
  const [etlOption, setEtlOption] = useState<string | null>(null);
  const [fileInput, setFileInput] = useState<File | null>(null);
  const [zipFile, setZipfile] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);
  const [projectOptions, setProjectOptions] = useState<string[]>([]);
  const [project, setProject] = useState<string | null>(null);
  const [assetName, setAssetName] = useState<string>("");
  const [old_asset_name, setOld] = useState<string>("");
  const [projectName, setProjectName] = useState<string>("");
  const [editStatus, setEditStatus] = useState<string>("");
  const [type, setType] = useState("file");
  const [fileTypes, setFileTypes] = useState<
    { label: string; value: string }[]
  >([]);
  const [fileValues, setFileValues] = useState<any>({});

  const fetchProjectNames = async () => {
    const response = await getProjectName();
    if (response?.success) {
      setProjectOptions(response?.data?.project_names);
    } else {
      setProjectOptions([]);
    }
  };

  const fetchFileTypes = async (project_name: string | null) => {
    if (!project_name) {
      return;
    }
    const response: any = await getFileTypes(project_name);

    try {
      const mappedData = response?.data?.map((item: any) => {
        return {
          label: item,
          value: item,
        };
      });

      setFileTypes(mappedData);
    } catch (error) {
      console.error("Error fetching file types:", error);
      setFileTypes([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProjectNames();
  }, []);

  useEffect(() => {
    if (project) {
      fetchFileTypes(project);
    } else {
      setFileTypes([]);
    }
  }, [project]);

  // const handleETLChange = (
  //   event: React.SyntheticEvent,
  //   value: { label: string; value: string } | null
  // ) => {
  //   if (value?.value === "cobol") {
  //     setType("text/*");
  //   } else if (value?.value === "sas") {
  //     setType(".zip");
  //   } else if (value?.value === "py") {
  //     setType(".py");
  //   } else if (value?.value === "excel" || value?.value === "XL" || "xl") {
  //     setType(
  //       ".xls, .xlsx, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  //     );
  //   } else if (value?.value === "pdf") {
  //     setType(".pdf, application/pdf");
  //   } else if (value?.value === ".png" || value?.value === "png") {
  //     setType("image/png");
  //   } else if (value?.value === "text" || value?.value === "txt") {
  //     setType(" .txt, text/plain");
  //   } else {
  //     setType("*");
  //   }
  //   fetchFileTypes(project);
  //   setEtlOption(value ? value.value : null);
  //   setFileInput(null);
  //   setZipfile(null);
  // };

  const handleETLChange = (
    event: React.SyntheticEvent,
    value: { label: string; value: string } | null
  ) => {
    if (value?.value === "cobol") {
      setType("text/*");
    } else if (value?.value === "sas") {
      setType(".zip");
    } else if (value?.value === "py") {
      setType(".py");
    } else if (
      value?.value === "excel" ||
      value?.value === "XL" ||
      value?.value === "xl"
    ) {
      setType(
        ".xls, .xlsx, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      );
    } else if (value?.value === ".png" || value?.value === "png") {
      setType("image/png");
    } else if (value?.value === "text" || value?.value === "txt") {
      setType(".txt, text/plain");
    } else if (value?.value === "doc" || value?.value === "docx") {
      setType(
        ".doc, .docx, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      );
    } else {
      setType("*");
    }

    fetchFileTypes(project); // Make sure 'project' is defined in your component
    setEtlOption(value ? value.value : null);
    setFileInput(null);
    setZipfile(null);
  };

  const handleEditAssetName = async () => {
    setOld(assetName);
    const result = await editAssetName(
      project ?? projectName,
      assetName,
      old_asset_name ? old_asset_name : ""
    );
    setEditStatus(
      result?.success
        ? ASSET_NAME_UPDATE_SUCCESS
        : `${ASSET_NAME_UPDATE_ERROR}: ${result?.message}`
    );
  };

  const handleFolderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event?.target?.files?.[0];
    if (file) {
      setZipfile(file);
      setOld(file.name);
      setAssetName(file.name);
      const assetType = "";
      const projectName = project ?? "";
      setFileValues({
        file: file,
        operation: "process_sas",
        name: file?.name,
        type: assetType,
        projectName: projectName,
      });
      // handleFileUpload(file, "process_sas", file.name, assetType, projectName);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event?.target?.files?.[0];
    if (file) {
      setFileInput(file);
      setOld(file.name);
      setAssetName(file.name);
      const assetType = "";
      const projectName = project ?? "";
      setFileValues({
        file: file,
        operation: "process_cobol",
        name: file?.name,
        type: assetType,
        projectName: projectName,
      });
      // handleFileUpload(
      //   file,
      //   "process_cobol",
      //   file.name,
      //   assetType,
      //   projectName
      // );
    }
  };

  const handleFileUpload = async () => {
    setLoading(true);
    try {
      if (!fileValues?.file) {
        throw new Error("File is required.");
      }
      if (!fileValues?.name || fileValues?.name.trim() === "") {
        throw new Error("Asset name is required.");
      }

      console.log("fileValues", fileValues);

      // return;
      const response = await uploadFile(
        fileValues?.file,
        fileValues?.operation,
        fileValues?.name,
        fileValues?.file?.type,
        fileValues?.projectName
      );

      if (response?.success) {
        setZipfile(null);
        setFileInput(null);

        dispatch({
          type: "SEND_ALERT",
          data: {
            ...notification,
            enable: true,
            type: "success",
            message: response.message || "File uploaded successfully",
            duration: 3000,
          },
        });
        setFileValues({});
        setAssetName("");
      } else {
        dispatch({
          type: "SEND_ALERT",
          data: {
            ...notification,
            enable: true,
            type: "error",
            message: response.message || "Failed to upload file",
            duration: 3000,
          },
        });
      }
    } catch (error: any) {
      dispatch({
        type: "SEND_ALERT",
        data: {
          ...notification,
          enable: true,
          type: "error",
          message: error.message || "An unexpected error occurred",
          duration: 3000,
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCreateAsset = () => {
    handleFileUpload();
  };

  return (
    <Box className="createKnowledge-test-container">
      <div className="createKnowledge-form-container">
        <div className="createKnowledge-form-container-field">
          <div className="label-textfield-container">
            <div className="field-label">Project Name</div>
            <Autocomplete
              size="small"
              fullWidth
              options={projectOptions}
              value={project}
              onChange={(event, newValue) => {
                setProject(newValue);
                fetchFileTypes(projectName);
              }}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" fullWidth />
              )}
            />
          </div>
        </div>
        <Box className="createKnowledge-form-container-field-autocomplete">
          <div className="label-textfield-container">
            <div className="field-label">Select Source Type</div>
            <div className="label-textfield-container">
              <Autocomplete
                size="small"
                fullWidth
                options={fileTypes}
                getOptionLabel={(option) => option?.label}
                onChange={handleETLChange}
                // onSelect={fetchFileTypes}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    className="test-autocomplete"
                  />
                )}
                isOptionEqualToValue={(option, value) =>
                  option?.value === value?.value
                }
              />
              {(etlOption === SAS || etlOption === COBOL || etlOption) &&
                !loading && (
                  <Button
                    style={{ width: "200px" }}
                    variant="contained"
                    component="label"
                    className="test-button"
                    endIcon={<InsertDriveFileIcon />}
                  >
                    Select File
                    <input
                      type={"file"}
                      accept={type}
                      onChange={
                        etlOption === SAS
                          ? handleFolderChange
                          : handleFileChange
                      }
                      className="create-knowledge-select-file"
                    />
                  </Button>
                )}
            </div>
          </div>
        </Box>

        <div className="createKnowledge-form-container-field">
          <div className="label-textfield-container">
            <div className="field-label">Asset Name</div>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={assetName}
              onChange={(e) => setAssetName(e.target.value)}
            />
            {/* <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={handleEditAssetName}
              className="edit-asset-name"
            >
              Edit
            </Button> */}
          </div>
        </div>
      </div>

      <Button
        variant="contained"
        color="primary"
        onClick={handleCreateAsset}
        className="create-asset-button"
      >
        Create Asset
      </Button>

      <Box mt={3}>{loading && <CircularProgress />}</Box>
    </Box>
  );
};

export default CreateKnowledge;
