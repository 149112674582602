import React, { useEffect, useState } from "react";
import { Alert, Box, IconButton } from "@mui/material";
import MUITable from "./MUITable";
import { GridColDef } from "@mui/x-data-grid";
import "./Styles.css";
import Chatbot from "../components/ChatBot/ChatBot";
import {
  deleteKnowledgeAssets,
  downloadAsset,
  getKnowledgeAssetsApi,
  previewCobol,
  previewSas,
} from "../services/BackendServices";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { HTMLComponent } from "../components/HTMLComponent";
import Snackbar, { SnackbarOrigin } from "@mui/material/Snackbar";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../redux/Hook";

interface asset {
  asset_id: string;
  asset_name: string;
  createdOn: string;
  type: string;
}

const initialValue = {
  asset_name: "",
  createdOn: "",
  type: "",
  asset_id: "",
};

interface State extends SnackbarOrigin {
  open: boolean;
}

const KnowledgeAssets: React.FC = () => {
  const notification = useAppSelector((state) => state.NotificationAlert);

  const dispatch = useDispatch();
  const [openChat, setOpenChat] = useState(false);
  const [loading, setLoading] = useState(false);
  const [assets, setAssets] = useState<asset[]>([]);
  const [selectedAsset, setSelectedAsset] = useState<asset>(initialValue);
  useEffect(() => {
    getKnowLedgeAssets();
  }, []);

  const handleDownloadAsset = async (params: any) => {
    try {
      const response = await downloadAsset(`${params?.asset_id}`);
      if (response?.success && response?.data) {
        const url = window.URL.createObjectURL(new Blob([response?.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", params?.asset_name);
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
      } else {
        console.error("Failed to download asset:", response?.message);
      }
    } catch (error) {
      console.error("Error downloading asset:", error);
    }
  };

  const columns: GridColDef<asset>[] = [
    {
      field: "project",
      headerName: "Project",
      minWidth: 200,
      editable: true,
      flex: 1,
    },
    {
      field: "asset_name",
      headerName: "Asset Name",
      minWidth: 400,
      editable: true,
      flex: 1,
      renderCell: (params: any) => (
        <div
          className="asset-name-cursor"
          onClick={() => handleDownloadAsset(params?.row)}
        >
          {params?.row?.asset_name}
        </div>
      ),
    },
    {
      field: "type",
      headerName: "Type",
      minWidth: 160,
      editable: true,
      flex: 1,
    },
    {
      field: "creation_date",
      headerName: "Creation Date",
      type: "string",
      minWidth: 250,
      editable: true,
      flex: 1,
    },
    {
      field: "preview",
      headerName: "Preview",
      type: "string",
      minWidth: 130,
      editable: true,
      flex: 1,
      renderCell: (params: any) => (
        <div>
          <IconButton
            color="primary"
            onClick={() => handlePreview(params?.row)}
            aria-label="edit"
          >
            <RemoveRedEyeIcon />
          </IconButton>
        </div>
      ),
    },
    {
      field: "interact",
      headerName: "Interact",
      type: "string",
      minWidth: 130,
      editable: true,
      flex: 1,
      renderCell: (params: any) => (
        <div>
          <IconButton
            color="primary"
            onClick={() => handleInteract(params?.row)}
            aria-label="edit"
          >
            <SmartToyIcon />
          </IconButton>
        </div>
      ),
    },
    {
      field: "delete",
      headerName: "Delete Asset",
      type: "string",
      minWidth: 130,
      editable: true,
      flex: 1,
      renderCell: (params: any) => (
        <div key={params?.row.asset_id}>
          <IconButton
            color="primary"
            onClick={() => handleDeleteAsset(params?.row?.asset_id)}
            aria-label="delete"
          >
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
  ];
  const handleInteract = (params: any) => {
    setSelectedAsset(params);
    setOpenChat(!openChat);
  };

  const handlePreview = (params: any) => {
    setSelectedAsset(params);
    if (params?.type == "cobal") {
      handlePreviewCobal(params);
    } else if (params?.type === "sas") {
      handlePreviewSas(params);
    } else {
      handlePreviewCobal(params);
    }
  };

  const handleDeleteAsset = async (asset_id: string) => {
    const result = await deleteKnowledgeAssets(asset_id);
    if (result?.success) {
      await getKnowLedgeAssets();
    } else {
      console.error("Error during deletion:", result?.message);
    }
  };
  const handlePreviewCobal = async (params: any) => {
    const response: any = await previewCobol(params?.asset_id);
    HTMLComponent(selectedAsset?.asset_name, response?.data);
  };

  const handlePreviewSas = async (params: any) => {
    const response: any = await previewSas(params?.asset_id);
    HTMLComponent(selectedAsset?.asset_name, response?.data);
  };

  const getKnowLedgeAssets = async () => {
    setLoading(true);
    const response: any = await getKnowledgeAssetsApi();
    if (response) {
      const updatedItems = response?.data?.mongo_knowledge_assets
        .filter((item: any) => item?.type !== "markdown")
        .map((item: any, index: any) => ({
          ...item,
          id: index + 1,
        }));
      setAssets(updatedItems);
    } else {
      dispatch({
        type: "SEND_ALERT",
        data: {
          ...notification,
          enable: true,
          type: "error",
          message: response?.data?.message ?? response?.message,
          duration: 3000,
        },
      });
    }
  };

  const closeChatBot = () => {
    setSelectedAsset(initialValue);
  };
  return (
    <>
      <Box display={"flex"} flexDirection={"column"} gap={2}>
        <div className="knowledge-table-container">
          <div>Knowledge Assets</div>
          <div>
            <MUITable
              columns={columns}
              rows={assets}
              pagination={false}
              tableHeight={"80vh"}
            />
          </div>
        </div>
      </Box>

      <div className="knowledgeAsset-chatbot-container">
        <Chatbot
          closeChatBot={closeChatBot}
          open={openChat}
          selectedAsset={selectedAsset?.asset_id}
        />
      </div>
    </>
  );
};

export default KnowledgeAssets;
