import React, { useState } from "react";
import { Button, TextField, Typography, Box } from "@mui/material";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import "./Styles.css";
import InteractChatbot from "./interactChatBot/InteractChatBot";

interface Message {
  text: string;
  isUser: boolean;
}

const Interact: React.FC = () => {
  return (
    <div>
      <InteractChatbot />
    </div>
  );
};

export default Interact;
