import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./Styles.css";
import {
  Button,
  TextField,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid2 as Grid,
} from "@mui/material";
import {
  projectDefinationApi,
  updateProjectDefinition,
} from "../services/BackendServices";
import { FILE_TYPES_REQUIRED, PROJECT_NAME_REQUIRED } from "./Constants";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

interface ProjectData {
  projectName: string;
  fileTypes: string;
  description: string;
  timestamp: string;
}

const ProjectDefinition = () => {
  const [submittedData, setSubmittedData] = useState<any>([]);

  useEffect(() => {
    getProjectDefinations();
  }, []);

  const formik = useFormik({
    initialValues: {
      projectName: "",
      fileTypes: "",
      description: "",
    },
    validationSchema: Yup.object({
      projectName: Yup.string().required(PROJECT_NAME_REQUIRED),
      fileTypes: Yup.string().required(FILE_TYPES_REQUIRED),
      description: Yup.string(),
    }),
    onSubmit: async (values) => {
      const fileTypesFormatted = values.fileTypes
        .split(",")
        .map((type) => type.trim());

      const projectData = {
        project_name: values.projectName,
        metadata: { description: values.description },
        file_types: fileTypesFormatted,
      };

      console.log("projectData", projectData);
      // return;
      const response = await updateProjectDefinition(
        projectData.project_name,
        projectData.metadata,
        projectData.file_types
      );

      if (response.success) {
        const timestamp = new Date().toLocaleString();
        getProjectDefinations();
        formik.resetForm();
      } else {
        console.error("Error:", response.message);
      }
    },
  });

  const getProjectDefinations = async () => {
    const response: any = await projectDefinationApi();

    if (response?.success) {
      setSubmittedData(
        response?.data?.projects.sort((a: any, b: any) =>
          b._id.localeCompare(a._id)
        )
      );
      formik.resetForm();
    } else {
      console.error("Error:", response?.message);
    }
  };

  const columns: GridColDef[] = [
    {
      field: "project_name",
      headerName: "Project Name",
      flex: 1,
    },
    {
      field: "file_types",
      headerName: "Types of File",
      flex: 1,
      renderCell: (params) => params.value?.join(", "), // Join array items with ", "
    },
    {
      field: "metadata",
      headerName: "Meta Data/Description",
      flex: 3,
      renderCell: (params) => params?.value?.description,
    },
    {
      field: "timestamp",
      headerName: "Timestamp",
      flex: 1,
      renderCell: (params) => new Date().toLocaleString(params.value), // Format timestamp
    },
  ];

  return (
    <div>
      <form onSubmit={formik.handleSubmit} className="project-form-container">
        <Grid
          container
          direction="row"
          wrap="wrap"
          spacing={2}
          size={{ xs: 6 }}
        >
          <Grid size={{ xs: 12 }}>
            <Typography variant="h5" gutterBottom>
              Project Definition
            </Typography>
          </Grid>
          <Grid size={{ xs: 12, sm: 4 }}>
            <Typography variant="body1">Project Name</Typography>
          </Grid>
          <Grid size={{ xs: 12, sm: 8 }}>
            <TextField
              fullWidth
              size="small"
              id="projectName"
              name="projectName"
              value={formik.values.projectName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.projectName && Boolean(formik.errors.projectName)
              }
              helperText={
                formik.touched.projectName && formik.errors.projectName
              }
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 4 }}>
            <Typography variant="body1">Types of File</Typography>
          </Grid>
          <Grid size={{ xs: 12, sm: 8 }}>
            <TextField
              fullWidth
              size="small"
              id="fileTypes"
              name="fileTypes"
              value={formik.values.fileTypes}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.fileTypes && Boolean(formik.errors.fileTypes)
              }
              helperText={formik.touched.fileTypes && formik.errors.fileTypes}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 4 }}>
            <Typography variant="body1">Meta Data/Description</Typography>
          </Grid>
          <Grid size={{ xs: 12, sm: 8 }}>
            <TextField
              fullWidth
              size="small"
              id="description"
              name="description"
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.description && Boolean(formik.errors.description)
              }
              helperText={
                formik.touched.description && formik.errors.description
              }
            />
          </Grid>
          <Grid
            size={{ xs: 12 }}
            alignItems="center"
            display="flex"
            flexDirection="row"
            justifyContent="center"
          >
            <Grid size={{ xs: 4 }}>
              <Button
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>

      <Grid
        container
        style={{ marginTop: 20 }}
        direction="row"
        wrap="wrap"
        spacing={2}
        size={{ xs: 6 }}
      >
        <DataGrid
          getRowId={(row) => row?._id}
          rows={submittedData}
          columns={columns}
        />
      </Grid>
    </div>
  );
};

export default ProjectDefinition;
