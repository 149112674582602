import React from "react";
import "./Styles.css";
import Awgment from "../assets/icons/augment-logo.svg";

const Dashboard = () => {
  return (
    <>
      <div className="home-header-text">
        {/* <div>AWGMENT Interface</div> */}
        <div>
          Welcome to AWGMENT
          <img
            src={Awgment}
            alt="comapny-logo"
            className="dashboard-awgment-logo"
          />
        </div>
      </div>
    </>
  );
};

export default Dashboard;

/* Control Flow: index -> App -> Wrapper -> Header -> SideNav -> NavListItems(Dashboard) -> Dashboard */
