import React from "react";
import "./App.css";
import store from "./redux/Store";
import { Provider } from "react-redux";
import Wrapper from "./pages/Wrapper";
import Navigation from "./Navigator";
import { BrowserRouter as Router } from "react-router-dom";

const App = () => {
  const getAppChildren = () => (
    <Router>
      <Provider store={store}>
        <Wrapper>
          <Navigation />
        </Wrapper>
      </Provider>
    </Router>
  );

  return <>{getAppChildren()}</>;
};

export default App;
