import React, { useState, useRef, useEffect } from "react";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import SendIcon from "@mui/icons-material/Send";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import CachedIcon from "@mui/icons-material/Cached";
import Tooltip from "@mui/material/Tooltip";
import LoadingSpinner from "../../components/LoadingSpinner";
import "../Styles.css";
import "./InteractChatBot.css";
import { Box } from "@mui/material";
import { interactVectorDb } from "../../services/BackendServices";

const InteractChatbot = () => {
  const [userMessage, setUserMessage] = useState("");
  const [chatMessages, setChatMessages] = useState([
    {
      content: "Hi welcome to Awgment! How can I assist you ?",
      className: "incoming",
    },
  ]);
  const [showChatbot, setShowChatbot] = useState(false);
  const chatboxRef = useRef<any>(null);

  const toggleChatbot = () => {
    setShowChatbot((prevState) => !prevState);
  };

  const handleInputChange = (e: any) => {
    setUserMessage(e.target.value);
  };

  const refreshChat = () => {
    setChatMessages([chatMessages[0]]);
  };

  const handleSendChat = () => {
    if (!userMessage.trim()) return;

    setChatMessages((prevMessages: any) => [
      ...prevMessages,
      { content: userMessage, className: "outgoing" },
      { loading: true, className: "incoming" },
    ]);
    setUserMessage("");

    generateResponse(userMessage);
  };

  const generateResponse = async (message: string) => {
    try {
      const response: any = await interactVectorDb(message);
      if (response?.success === true) {
        const responseData = response?.data;
        setChatMessages((prevMessages) => [
          ...prevMessages.slice(0, -1),
          { content: responseData.trim(), className: "incoming" },
        ]);
        scrollChatToBottom();
      } else {
        setChatMessages((prevMessages) => [
          ...prevMessages.slice(0, -1),
          { content: response?.message, className: "incoming" },
        ]);
      }
    } catch (error) {
      setChatMessages((prevMessages) => [
        ...prevMessages,
        {
          content: "Oops! Something went wrong. Please try again.",
          className: "error",
        },
      ]);
    }
  };

  const scrollChatToBottom = () => {
    if (chatboxRef.current) {
      chatboxRef.current.scrollTop = chatboxRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollChatToBottom();
  }, [chatMessages]);

  return (
    <Box display={"flex"} flexDirection={"column"} gap={1}>
      <div className="interact-chatbot-heading">
        Total embeddings in vector database:{" "}
        <span className="interact-chatbot-heading-number ">{5}</span>
      </div>
      <div className="interact-chatbot">
        <ul className="chatbox" ref={chatboxRef}>
          {chatMessages?.map((message: any, index) => (
            <li key={index} className={`chat ${message.className}`}>
              {message.className === "incoming" && (
                <span className="icon">
                  <SmartToyIcon style={{ marginTop: "4px" }} />
                </span>
              )}

              {message?.loading && (
                <span className="loading-spinner">
                  <LoadingSpinner />
                </span>
              )}
              {message.content && <p>{message.content}</p>}
            </li>
          ))}
        </ul>
        <div className="chat-input">
          {/* <div id="Refresh-btn" onClick={refreshChat}>
            <Tooltip title="Refresh Chat" arrow enterDelay={500}>
              <CachedIcon />
            </Tooltip>
          </div> */}
          <textarea
            value={userMessage}
            onChange={handleInputChange}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                handleSendChat();
              }
            }}
            placeholder="Enter a message..."
            spellCheck="false"
            required
          />

          <span id="send-btn" onClick={handleSendChat}>
            <SendIcon />
          </span>
        </div>
      </div>
    </Box>
  );
};

export default InteractChatbot;
