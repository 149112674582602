import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate } from "react-router-dom";
import { routes as appRoutes } from "../Routes";
import { Tooltip } from "@mui/material";

export const NavListItems = () => {
  const navigate = useNavigate();

  const handleRouteClick = (url: string) => {
    navigate(url);
  };

  return (
    <React.Fragment>
      <div>
        {appRoutes?.map((item: any) => {
          return (
            <Tooltip title={item?.title} placement="right-start">
              <ListItemButton
                onClick={() => handleRouteClick(item?.path)}
                key={item?.index}
              >
                <ListItemIcon>
                  <item.icon />
                </ListItemIcon>
                <ListItemText primary={item?.title} />
              </ListItemButton>
            </Tooltip>
          );
        })}
      </div>
    </React.Fragment>
  );
};

/* Control Flow: index -> App -> Wrapper -> Header -> SideNav -> NavListItems */
